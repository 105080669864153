<template>
  <div class="user-nav">
    <router-link
      v-if="!isLogin"
      to="/"
      tag="span"
      class="user-nav__item"
    >Главная</router-link>

    <router-link
      v-if="isLogin"
      to="/shop"
      tag="span"
      class="user-nav__item"
    >Магазин</router-link>

    <router-link
      v-if="isLogin"
      to="/profile/orders"
      tag="span"
      class="user-nav__item"
    >Мои заказы</router-link>

    <router-link
      v-show="isLogin && cart.length && $route.fullPath !== '/cart'"
      to="/cart"
      tag="span"
      class="user-nav__item"
    >Корзина</router-link>

    <router-link
      to="/payinfo"
      tag="span"
      class="user-nav__item"
    >Оплата</router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'UserNav',

  computed: {
    ...mapGetters(['isLogin']),
    ...mapGetters({cart: 'items'})
  },
}
</script>

<style lang="scss">
.user-nav {
  position: relative;
  display: flex;
  margin-top: 40px;
  padding: 15px 0;
  &:after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    background: #E0E0E0;
  }

  @include sm {
    display: none;
  }
}

.user-nav__item {
  position: relative;
  cursor: pointer;
  font-size: 18px;

  @include sm {
    margin-right: 80px;
    font-size: 16px;
  }
  @include md {
    margin-right: rem-md(80px);
  }
  @include lg {
    margin-right: rem-lg(80px);
  }

  &.router-link-exact-active {
    &:after {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -15px;
      display: block;
      content: '';
      width: 100%;
      height: 3px;
      background: #2F6BF0;
      z-index: 1;
    }
  }
}
</style>
