<template>
  <div class="container">
    <user-nav/>

    <div class="form-box">
      <div class="page-title">Вход</div>
      <div class="form">

        <div
          class="form__input-wrap"
        >
          <the-mask
            id="login"
            type="tel"
            :class="['form__input', {'invalid': $v.login.$error}]"
            :mask="['+7 (###) ###-##-##']"
            v-model.trim="$v.login.$model"
            placeholder="НОМЕР ТЕЛЕФОНА"
            autocomplete="off"
          />
          <div class="helper-text text-red" v-if="$v.login.dirty && !$v.login.required">Заполните это поле</div>
          <div class="helper-text text-red" v-if="!$v.login.numeric">Должно содержать только цифры</div>
          <div class="helper-text text-red" v-if="!$v.login.minLength">Не должно быть меньше {{$v.login.$params.minLength.min}} символов</div>
          <div class="helper-text text-red" v-if="!$v.login.maxLength">Не должно быть больше {{$v.login.$params.maxLength.max}} символов</div>
        </div>

        <div
          class="form__input-wrap"
        >
          <input
            id="password"
            type="password"
            :class="['form__input', {'invalid': $v.password.$error}]"
            v-model.trim="$v.password.$model"
            placeholder="ПАРОЛЬ"
            autocomplete="off"
          >
          <div class="helper-text text-red" v-if="$v.password.dirty && !$v.password.required">Заполните это поле</div>
          <div class="helper-text text-red" v-if="!$v.password.minLength">Не должно быть меньше {{$v.password.$params.minLength.min}} символов</div>
          <div class="helper-text text-red" v-if="!$v.password.maxLength">Не должно быть больше {{$v.password.$params.maxLength.max}} символов</div>
        </div>

        <div
          class="form__checkbox-wrap"
        >
          <p class="new-user-wrapper">
            <label>
              <input
                class="form__checkbox-wrap__check"
                type="checkbox"
              >
              <span class="form__checkbox-wrap__text">Я новый пользователь</span>
            </label>
          </p>
        </div>

        <div
          class="form__checkbox-wrap"
        >
          <router-link to="/restore">Забыли пароль?</router-link>
        </div>

        <button
          :class="['btn lg btn--login', {'disabled': isLoading}]"
          @click="submitHandler()"
        >Войти</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators'
import UserNav from '@/components/UserNav'


export default {
  name: 'Login',
  components: {UserNav},
  data: () => ({
    isLoading: false,
    login: '',
    password: ''
  }),

  validations: {
    login: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(30),
    }
  },

  methods: {
    ...mapActions(['loginUser']),
    lock() { this.isLoading = true },
    unlock() { this.isLoading = false },
    async submitHandler() {

      if (this.$v.$invalid) {
        this.$v.login.$touch()
        this.$v.password.$touch()
        M.Toast.dismissAll()
        M.toast({ html: 'Пожалуйста, заполните указанные поля!' })
        return;
      }

      let formData = {
        login: (this.login.substr(0,1) === '7') ? this.login : `7${this.login}`,
        password: this.password
      }

      try {
        this.lock()
        await this.loginUser(formData)
        await this.$router.push(this.$router.currentRoute.query.redirect || '/shop')
      }
      catch (ex) {
        let errors = ex?.data?.errors
        let error = errors?.password?.invalid

        if (error) M.toast({ html: error })
      }
      finally {
        this.unlock()
      }
    }
  }
}
</script>

<style scoped>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .new-user-wrapper {
    margin: 0;
  }
  .user-nav {
    align-self: stretch;
  }
</style>
